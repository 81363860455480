<template>
  <NuxtLayout name="user-auth">
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup>
const token = useCookie('auth.access_token');

if (token.value) {
  navigateTo('/claims/todo', { replace: true });
}

useHead({
  title: 'Login',
});
</script>

<style>
/* ... */

.slide-in-enter-active,
.slide-in-leave-active {
  transition: all 0.5s;
}

.slide-in-enter-from {
  position: absolute;
  opacity: 0;
  left: 0;
}

.slide-in-enter-to {
  position: static;
  opacity: 1;
}

.slide-in-leave-to {
  position: absolute;
  opacity: 0;
  left: 100%;
}

.slide-in-leave-from {
  position: static;
  opacity: 1;
}
</style>
